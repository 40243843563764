*{
  box-sizing: border-box;
}

.container{
  margin: auto;
  width: 25vw;
  align-items: center;
}

.mainTitle{
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.mainSearch{
  margin: 10px auto;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
}

.mainSearch img{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0.25rem;
  position: absolute;
  z-index: 2;
}

.mainSearch input{
  display: block;
  height: 2.5rem;
  font-size: 1.5rem;
  text-indent: 2.5rem;
  width: 100%;
  border-radius: 5px;
}

.newUserButton{
  width: 100%;
  margin: 10px auto;
}

.newUserButton button{
  width: 100%;
  height: 2rem;
  border: 0;
  border-radius: 5px;
  background-color: rgb(102,161,130);
  font-size: 1.1rem;
  color: black;
  filter: drop-shadow(0px 1px 1px #000000);
}

.newUserButton button:hover{
  background-color: rgba(102,161,130,0.8);
}

.newUserButton button:active{
  transform: translateY(1px);
  filter: none;
}

/* Navbar */
nav{
  background-color: #C84630;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 5px;
}

nav a{
  color: white;
  display: block;
  padding: 0.5rem 1rem;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

nav a:hover{
  background-color: rgba(0,0,0,0.1);
}

nav a.right{
  border-radius: 0 5px 5px 0;
}

nav a.left{
  border-radius: 5px 0 0 5px
}

nav .isActive{
  background-color: rgba(0,0,0,0.25);
}

.logIn{
  margin: 1rem 0px;
}

.signOut{
  width: 100%;
  background-color: #C84630;
  border: 0;
  margin: 0.5rem 0;
  height: 1.5rem;
  border-radius: 5px;;
  cursor: pointer;
}

.signOut:hover{
  opacity: 0.9;
}

.signOut:active{
  transform: translate(0,2px);
}

@media (min-width: 650px) and (max-width: 1200px){
  .container{
    width: 60vw;
  }
}

@media (max-width: 650px){
  .container{
    width:90vw;
  }
}

