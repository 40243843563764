.modal {
  display: block;
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  pointer-events: none;
}

.modalBackground{
  display: block;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modalBody{
  position: relative;
  background-color: #fefefe;
  margin: auto;
  top:15%;
  width: 35%;
  border-radius: 5px;
  pointer-events: all;
}

.modalHeader{
  border-radius: 4px 4px 0 0;
  background: darkslategray;
  font-size: 1.5rem;
  padding: 0.25rem;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
}

.modalHeader span{
  flex-grow: 1;
}

.modalHeader div{
  height: 3rem;
  width: 3rem;
}

.modalHeader div svg .centralPlus{
  fill: white;
}

.modalHeader div svg:hover > .centralPlus{
  fill: #A5DC69;
}

.modalHeader div svg .newOrderIconBackground{
  fill:white;
}

.modalHeader div svg:hover > .newOrderIconBackground{
  fill:grey;
}

.modalContent{
  padding: 0.5rem;
  max-height: 60vh;
  overflow: auto;
}

.item{
  width: 100%;
}

.modalContainer{
  margin:auto;
  width: 80%;
}