.infoButton{
  margin: 1em auto 0;
  box-sizing: border-box;
  height: 2em;
  width: 100%;
  background-color: rgba(255,255,255,0.35);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.infoButton:hover{
  background-color: rgba(255,255,255,0.55);
}

.userName{
  font-size: 1.5rem;
  padding: 0.1em;
}

.userItem{
  background-color: cadetblue;
  color: black;
  border-radius: 5px;
  padding: 5px;
  margin: 0.25rem auto;
}

.userDivide{
  border: 0.5px solid lightgrey;
  opacity: 0.5;
  border-radius: 0.25px;
  margin:4px
}

.userDetail img{
  height: 1em;
  display: inline-block;
}
