.orderForm label{
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  margin: 0.25rem auto;
  padding: 0.25rem 0;
}

.orderForm label span{
  padding: 1rem;
  text-align: left;
}

.orderForm label textarea{
  resize: none;  
}

.orderForm label select{
  font-size: 0.8rem;
  height: 1.25rem;
}

.orderForm .buttons{
  display: flex;
  justify-content: center;
}

.orderForm button{
  margin: 0 0.5rem;
  width: 25%;
  height: 2rem;
  font-size: 1rem;
  border: black 1px solid;
  border-radius: 5px;
  filter: drop-shadow(0.1px 0.1px 0.5px #333333)
}


.orderForm .cancel{
  background-color: rgb(229,89,52);
}

.orderForm .cancel:hover{
  background-color: rgb(229,89,52,0.9);
  filter: none
}

.orderForm .submit{
  background-color: rgba(50, 233, 132,1);
}

.orderForm .submit:hover{
  background-color: rgba(50, 233, 132,0.9);
  filter: none
}

.alert{
  background-color: #E55934;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 0.1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert img{
  height: 2rem;
}


@media (max-width: 650px){
 .orderForm label{
   grid-template-columns: 1fr;

  }
  .orderForm label span{
    padding: 0 0 0.1rem 0;
    margin: 0 0 0.2rem 0;
 }
}

