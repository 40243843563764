/* Main Class */
.orderItem{
  width: 100%;
  background-color: gold;
  color: black;
  border-radius: 5px;
  padding: 5px;
  margin: 0.25rem auto;
  position: relative;
}

.orderStatusDropdown{
  width: 40%;
  min-width: 120px;
  background-color: transparent;
  font-size: 1rem;
}

.delete{
  cursor: pointer;
}

.delete img{
  position: absolute;
  height: 1.5rem;
  right: 0.25rem;
  bottom: 0.25rem;
}

.is-completed{
  background-color: green;
}

.is-outstanding{
  background-color: rgb(255,77,13);
}

.orderDivide{
  border: 0.5px solid lightgrey;
  opacity: 0.5;
  border-radius: 0.25px;
  margin:4px
}

.orderDetail{
  display: grid;
  grid-template-columns: 2em auto;
  align-items: center;
}

.orderDescriber{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.orderDescriber img{
  align-self: center;
  vertical-align: middle;
  height: 1rem;
  display: inline-block;
}

.orderItems{
  background-color: rgba(128, 128, 128, 0.05);
  border-radius: 5px;
  white-space: pre-wrap;
}

@media (min-width: 650px) and (max-width: 1100px){
  .modalBody{
    width: 60%;
  }
}

@media (max-width: 650px){
  .modalBody{
    width: 80%
  }
}
